import React from "react"
import { graphql } from "gatsby"

// Components
import { Layout } from "../components/layout"
import { Lightbox } from "../components/gallery"
import { PaginationButton, PaginationWrapper } from "../components/pagination"

const GalleryTemplate = ({ data, pageContext }) => {
  const doc = data.allSanityPhoto.edges
  if (!doc) return null

  // Gallery Page Navigation
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/` : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <Lightbox images={doc} />
      <PaginationWrapper>
        {!isFirst && (
          <PaginationButton to={`/gallery${prevPage}`} rel="prev">
            ← Previous Page
          </PaginationButton>
        )}
        {!isLast && (
          <PaginationButton to={`/gallery${nextPage}`} rel="next">
            Next Page →
          </PaginationButton>
        )}
      </PaginationWrapper>
    </Layout>
  )
}

export const query = graphql`
  query GalleryQuery($skip: Int!, $limit: Int!) {
    allSanityPhoto(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          caption
          date
          id
          image {
            asset {
              url
              fluid(maxWidth: 1200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default GalleryTemplate
