import React from "react"
import styled from "styled-components"

// Components

const Wrapper = styled.div`
  margin: 1rem;
  position: relative;
`

const Caption = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(51, 51, 51);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  color: ${({ theme }) => theme.colors.white};
  padding: 2rem 1rem 1rem 1rem;
  margin: 0;
  display: block;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
`

const GalleryItem = ({ children, caption }) => {
  return (
    <Wrapper>
      {children}
      <Caption>{caption}</Caption>
    </Wrapper>
  )
}

export default GalleryItem
