import React, { Component } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

// Components
import Caption from "./Caption"
import StyledImage from "./StyledImage"
import Gallery from "./Gallery"
import GalleryItem from "./GalleryItem"
import Button from "../../button"
import Modal from "./Modal"
import Content from "./Content"
import Controls from "./Controls"
import LeftRight from "./LeftRight"

class Lightbox extends Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({
      showLightbox: !this.state.showLightbox,
      selectedImage: index,
    })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left arrow key
        if (this.state.selected > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right arrow key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ slectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render() {
    const { images } = this.props
    const { showLightbox, selectedImage } = this.state
    return (
      <>
        <Gallery>
          {images.map((img, i) => (
            <GalleryItem key={img.node.id} caption={img.node.caption}>
              <a
                href={img.node.image.asset.url}
                alt="Image"
                onClick={e => this.handleClick(e, i)}
              >
                <StyledImage
                  sizes={img.node.image.asset.fluid}
                  backgroundColor="#7C1234"
                />
              </a>
            </GalleryItem>
          ))}
        </Gallery>
        <Modal visible={showLightbox} onKeyUp={e => this.handleKeyDown(e)}>
          <Content>
            <Img
              fluid={images[selectedImage].node.image.asset.fluid}
              backgroundColor="#7C1234"
            />
            <Caption>{images[selectedImage].node.caption}</Caption>
            <Controls>
              <Button onClick={this.closeModal}>Close</Button>
              <LeftRight>
                <Button
                  onClick={this.goBack}
                  disabled={selectedImage === 0}
                  primary
                >
                  Previous
                </Button>
                <Button
                  onClick={this.goForward}
                  disabled={selectedImage === images.length - 1}
                >
                  Next
                </Button>
              </LeftRight>
            </Controls>
          </Content>
        </Modal>
      </>
    )
  }
}

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
}

export default Lightbox
