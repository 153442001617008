import styled from "styled-components"

const Caption = styled.p`
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  font-size: 1rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin: 0;
  z-index: 10;
`

export default Caption
