import styled from "styled-components"

const Button = styled.button`
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  display: inline-block;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.primary.shade};
  }
`

export default Button
