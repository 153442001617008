import { Link } from "gatsby"
import styled from "styled-components"

const PaginationButton = styled(Link)`
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.white};
  border: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  margin: 1rem;

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.primary.shade};
  }
`

export default PaginationButton
